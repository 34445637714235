<template>
    <div class="min-h-screen">
        <section class="bg-[url('https://flowbite.s3.amazonaws.com/blocks/marketing-ui/authentication/background.jpg')] bg-no-repeat bg-cover bg-center bg-gray-700 bg-blend-multiply bg-opacity-90">
            <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen pt:mt-0">
                <a href="https://kray.eu" class="flex items-center mb-6 text-2xl font-semibold text-white">
                    <img class="h-8 mr-2" src="/img/logo-tan.png" alt="logo">
                </a>
                <div class="max-w-3xl w-full">
                    <slot />
                </div>
            </div>
        </section>
    </div>
</template>
